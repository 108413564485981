import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Components
import ContactHero from './components/contactHero'
import ContactForm from './components/contactForm'
import ContactOffice from './components/contactOffice'

function Contact() {
  return (
    <Layout>
      <SiteNav fullLogo />
      <ContactHero />
      <ContactForm />
      <ContactOffice />
    </Layout>
  )
}

export default Contact
