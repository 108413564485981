import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

function ContactOffice() {
  return (
    <Section className="is-medium">
      <Container className="content">
        <Columns className="">
          <Columns.Column className="is-6 has-google-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2356.5770919196543!2d-1.557038584144514!3d53.79701418007602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48795ea129e684b1%3A0xeae2d6d8f48ffa8b!2sPocketworks%20Mobile%20Ltd!5e0!3m2!1sen!2suk!4v1579870920006!5m2!1sen!2suk"
              width="600"
              height="600"
              title="Google-Maps"
              className="has-shadow"
            />
          </Columns.Column>

          <Columns.Column className="is-5">
            <h2 className="title">Visiting?</h2>
            <p>
              <span className="icon">
                <i className="fas fa-car" />
              </span>
              <a
                className=""
                target="_blank"
                href="https://goo.gl/maps/RMW3cCNdmCACSMKdA"
                rel="noreferrer"
              >
                {' '}
                See directions on Google Maps
              </a>
            </p>

            <p>
              <span className="icon">
                <i className="fas fa-parking" />
              </span>{' '}
              Paid parking on{' '}
              <a href="https://goo.gl/maps/q49baGM1adRSkZnC9">Park Square</a>,
              just a 1 min walk away.
            </p>
            <p>
              <span className="icon">
                <i className="fas fa-subway" />
              </span>{' '}
              5 minute walk from Leeds train station.
            </p>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default ContactOffice
