import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Hero, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'

function ContactHero() {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="container content">
          <Columns className="is-tablet is-vcentered is-centered">
            <Columns.Column className="is-6 has-text-centered">
              <h1 className="title is-size-1 is-size-3-mobile">Let's talk</h1>
              <p className="is-size-4">
                <a href="tel:+441134660302" className="has-text-primary">
                  +44 113 466 0302
                </a>
                <br />
                <a
                  href="mailto:hello@pocketworks.co.uk"
                  className="has-text-primary"
                >
                  hello@pocketworks.co.uk
                </a>
              </p>
              <p>
                Pocketworks Mobile Ltd,
                <br />
                AHQ, East Parade, Leeds, LS1 2BH
              </p>
              <p>
                <span className="icon has-text-danger">
                  <i className="fa-solid fa-starship-freighter" />
                </span>
                &nbsp; Looking to <b>join the team?</b> Check out our{' '}
                <Link to="/about/careers" className="has-text-primary">
                  careers page
                </Link>
                .
              </p>
            </Columns.Column>
          </Columns>
          <Separator className="is-hidden-mobile mt-6" />
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default ContactHero
