import React from 'react'
import { Section, Container, Columns, Box } from 'react-bulma-components'
// Components
import Separator from 'components/separator'

function ContactForm() {
  return (
    <Section className="has-background-light is-medium pt-3">
      <Container className="content">
        <Columns className="is-centered">
          <Columns.Column className="is-7">
            <Box className="p-6 is-shadowed is-rounded">
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                action="/contact/thanks"
              >
                <input type="hidden" name="form-name" value="contact" />
                <h3 className="title has-text-centered pb-5 pt-0">
                  Your app enquiry
                </h3>
                <p className="is-hidden">
                  <label>
                    Don’t fill this out if you’re human:{' '}
                    <input name="bot-field" />
                  </label>
                </p>
                <Columns>
                  <Columns.Column className="is-6">
                    <div className="field">
                      <label className="label">
                        Your name <span className="has-text-primary">*</span>
                      </label>
                      <div className="control">
                        <input
                          className="input is-success"
                          type="text"
                          name="name"
                          required
                        />
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column className="is-6">
                    <div className="field">
                      <label className="label">
                        Your email <span className="has-text-primary">*</span>
                      </label>
                      <div className="control">
                        <input
                          className="input is-success"
                          type="email"
                          name="email"
                          required
                        />
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column>
                    <div className="field">
                      <label className="label">Your position</label>
                      <div className="control">
                        <input
                          className="input is-success"
                          type="text"
                          name="position"
                        />
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column>
                    <div className="field">
                      <label className="label">Your phone number</label>
                      <div className="control">
                        <input
                          className="input is-success"
                          type="text"
                          name="telephone"
                        />
                      </div>
                    </div>
                  </Columns.Column>
                </Columns>

                <div className="field mt-3">
                  <label className="label">How can we help you?</label>
                  <div className="control">
                    <textarea
                      rows={10}
                      className="textarea is-success"
                      name="message"
                      required
                    />
                  </div>
                </div>

                <div className="field mt-0">
                  <label className="label">
                    How much do you expect to invest?
                  </label>

                  <div className="control">
                    <label className="radio pr-1">
                      <input
                        type="radio"
                        name="budget"
                        className="pr-1 mr-1"
                        value="Not Sure"
                      />
                      Not sure
                    </label>
                    <label className="radio">
                      <input
                        type="radio"
                        name="budget"
                        className="pr-1 mr-1"
                        value="£100k+"
                      />
                      &pound;100K+
                    </label>
                    <label className="radio">
                      <input
                        type="radio"
                        name="budget"
                        className="pr-1 mr-1"
                        value="£200k+"
                      />
                      &pound;200K+
                    </label>
                    <label className="radio">
                      <input
                        type="radio"
                        name="budget"
                        className="pr-1 mr-1"
                        value="£500k+"
                      />
                      &pound;500K+
                    </label>
                    <label className="radio">
                      <input
                        type="radio"
                        name="budget"
                        className="pr-1 mr-1"
                        value="£1M+"
                      />
                      &pound;1M+
                    </label>
                  </div>
                </div>

                <p className="has-text-centered mt-6">
                  <button
                    className="button is-primary is-leafy is-medium is-flex"
                    type="submit"
                  >
                    Send my enquiry{' '}
                    <span className="icon ml-2 mt-1">
                      <i className="fas fa-arrow-right fa-sm" />
                    </span>
                  </button>
                </p>
              </form>
            </Box>
          </Columns.Column>
        </Columns>
        <Separator className="is-hidden-mobile mt-6" rtl />
      </Container>
    </Section>
  )
}

export default ContactForm
